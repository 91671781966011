import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import Switcher from '../Elements/Switcher';

var bgimage = require('./../../images/background/bg-site.png');

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.currentstate = { logo: require('./../../images/jsinterio/logo/logo-dark-bg.png') };
  }

  updateFooterLogo = (updatedlogo) => {
    this.currentstate.logo = updatedlogo;
  };

  render() {
    return (
      <>
        <footer className="site-footer footer-large  footer-dark	footer-wide">
          {/* <div className="container call-to-action-wrap bg-no-repeat bg-center" style={{ backgroundImage: 'url('+bgimage+')' }}>
                        <div className="p-a30 bg-primary ">
                            <div className="row">
                                <div className="col-md-8 col-sm-8">
                                    <div className="call-to-action-left text-black">
                                        <h4 className="text-uppercase m-b10 m-t0">Subscribe to our newsletter!</h4>
                                        <span>Never Miss Anything From Construx By Signing Up To Our Newsletter.</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <div className="call-to-action-right">
                                        <div className="widget_newsletter">
                                            <div className="newsletter-bx">
                                                <form role="search" action="./">
                                                    <div className="input-group">
                                                        <input name="news-letter" className="form-control" placeholder="ENTER YOUR EMAIL" type="text" />
                                                        <span className="input-group-btn">
                                                            <button type="submit" className="site-button"><i className="fa fa-paper-plane-o" /></button>
                                                        </span>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
          {/* FOOTER BLOCKES START */}
          <div className="footer-top overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                {/* ABOUT COMPANY */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_about">
                    {/*<h4 class="widget-title">About Company</h4>*/}
                    <div className="logo-footer clearfix p-b15">
                      <NavLink to={'./'}>
                        <img src={this.currentstate.logo} alt="" />
                      </NavLink>
                    </div>
                    <p className="max-w400">
                      Immerse yourself in a world of stylish and innovative interior concepts that will revolutionize the focal point of your home.
                    </p>
                    <ul className="social-icons  mt-social-links">
                      <li>
                        <a target="_blank" href={'https://www.youtube.com/channel/UC0NESYulqRgGABZNx2s-Tfg'} className="fa fa-youtube-play" />
                      </li>
                      <li>
                        <a target="_blank" href={'https://www.facebook.com/profile.php?id=100092644549400'} className="fa fa-facebook" />
                      </li>
                      <li>
                        <a target="_blank" href={'https://www.instagram.com/jsinteriors37/'} className="fa fa-instagram" />
                      </li>
                      <li>
                        <a target="_blank" href={'https://wa.me/message/EVCJCVMF37XLK1'} className="fa fa-whatsapp" />
                      </li>
                    </ul>
                  </div>
                </div>
                {/* RESENT POST */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_address_outer">
                    <h4 className="widget-title">Contact Us</h4>
                    <ul className="widget_address">
                      <li>JS Interiors, Sy. No. 51, New No. 51/1A, Halehalli Village, Bidarahalli Hobli, Bengaluru Urban, Karnataka. 560049</li>
                      <li>govindareddy@jsinterio.com</li>
                      <li>(+91) 960-605-7771</li>
                      <li>(+91) 960-605-7772</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* USEFUL LINKS */}
              {/* <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3"> */}
              {/* <div className="widget widget_services inline-links">
                                        <h4 className="widget-title">Useful links</h4>
                                        <ul>
                                            <li><NavLink to={"/about"}>About Us</NavLink></li>
                                            <li><NavLink to={"/project-grid"}>Services</NavLink></li>
                                            <li><NavLink to={"/blog-grid"}>Our Projects</NavLink></li>
                                            <li><NavLink to={"/contactus"}>Contact Us</NavLink></li>
                                        </ul>
                                    </div> */}
              {/* </div> */}
              {/* TAGS */}
              {/* <div className="col-lg-3 col-md-6 col-sm-6"> */}
              {/* <div className="widget recent-posts-entry-date">
                                        <h4 className="widget-title">Recent Post</h4>
                                        <div className="widget-post-bx">
                                            <div className="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                                <div className="mt-post-date text-center text-uppercase text-white p-tb5">
                                                    <strong className="p-date">24</strong>
                                                    <span className="p-month">Mar</span>
                                                    <span className="p-year">2022</span>
                                                </div>
                                                <div className="mt-post-info">
                                                    <div className="mt-post-header">
                                                        <h6 className="post-title"><NavLink to={"/post-sidebar"}>On these beams, we’re building dreams.</NavLink></h6>
                                                    </div>
                                                    <div className="mt-post-meta">
                                                        <ul>
                                                            <li className="post-author"><i className="fa fa-user" />By Admin</li>
                                                            <li className="post-comment"><i className="fa fa-comments" /> 28</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                                <div className="mt-post-date text-center text-uppercase text-white p-tb5">
                                                    <strong className="p-date">30</strong>
                                                    <span className="p-month">Jan</span>
                                                    <span className="p-year">2022</span>
                                                </div>
                                                <div className="mt-post-info">
                                                    <div className="mt-post-header">
                                                        <h6 className="post-title"><NavLink to={"/post-sidebar"}>We’ll be a sensation for you next renovation</NavLink></h6>
                                                    </div>
                                                    <div className="mt-post-meta">
                                                        <ul>
                                                            <li className="post-author"><i className="fa fa-user" />By Admin</li>
                                                            <li className="post-comment"><i className="fa fa-comments" /> 29</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
              {/* </div> */}
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <h4 className="widget-title">Interior Designers</h4>
                  <ul>
                    <li>Interior Designers in Bangalore</li>
                    <li>Interior Designers in Karnataka</li>
                    <li>Interior Designers in Bengaluru</li>
                    <li>Interior Designers in Nellore</li>
                    <li>Interior Designers in Vizag</li>
                    <li>Interior Designers in Mumbai</li>
                    <li>Interior Designers in Kolar</li>
                    <li>Interior Designers in South India</li>
                    <li>Interior Designers in Chennai</li>
                    <li>Interior Designers in Hyderabad</li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <h4 className="widget-title">Modular Kitchen</h4>
                  <ul>
                    <li>Modular Kitchen in Bangalore</li>
                    <li>Modular Kitchen in India</li>
                    <li>Modular Kitchen in Nellore</li>
                    <li>Modular Kitchen in Chennai</li>
                    <li>Modular Kitchen in Vizag</li>
                    <li>Modular Kitchen in Hyderabad</li>
                    <li>Modular Kitchen in Tirupathi</li>
                    <li>Modular Kitchen in Vijayawada</li>
                    <li>Modular Kitchen in Mysore</li>
                    <li>Modular Kitchen in Chittor</li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <h4 className="widget-title">Wardrobe Design</h4>
                  <ul>
                    <li>Wardrobe Design in Bangalore</li>
                    <li>Wardrobe Design in India</li>
                    <li>Wardrobe Design in Udupi</li>
                    <li>Wardrobe Design in Shimoga</li>
                    <li>Wardrobe Design in Coimbatore</li>
                    <li>Wardrobe Design in Mumbai</li>
                    <li>Wardrobe Design in Telangana</li>
                    <li>Wardrobe Design in Tamil Nadu</li>
                    <li>Wardrobe Design in Chennai</li>
                    <li>Wardrobe Design in Hyderabad</li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <h4 className="widget-title">Design Idea</h4>
                  <ul>
                    <li>Living Room Designs</li>
                    <li>Modular Kitchen Designs</li>
                    <li>Wardrobe Designs</li>
                    <li>Master Bedroom Designs</li>
                    <li>Kids Room Designs</li>
                    <li>Kitchen Wall Tiles Designs</li>
                    <li>Kitchen False Ceiling Designs</li>
                    <li>Balcony Designs</li>
                    <li>TV Unit Designs</li>
                    <li>Bathroom Designs</li>
                  </ul>
                </div>
                {/* NEWSLETTER */}
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                <div className="mt-footer-bot-center">
                  <span className="copyrights-text">© 2023 JS INTERIORS</span>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />
      </>
    );
  }
}

export default Footer;
