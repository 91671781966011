import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/kidsroom/kids-room-1.jpeg'),
    image: require('./../../images/jsinterio/services/kidsroom/kids-room-1.jpeg'),
    title: 'Kids Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/kidsroom/kids-room-2.jpeg'),
    image: require('./../../images/jsinterio/services/kidsroom/kids-room-2.jpeg'),
    title: 'Kids Room',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/kidsroom/kids-room-3.jpeg'),
    image: require('./../../images/jsinterio/services/kidsroom/kids-room-3.jpeg'),
    title: 'Kids Room',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/kidsroom/kids-room-4.jpeg'),
    image: require('./../../images/jsinterio/services/kidsroom/kids-room-4.jpeg'),
    title: 'Kids Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/kidsroom/kids-room-5.jpeg'),
    image: require('./../../images/jsinterio/services/kidsroom/kids-room-5.jpeg'),
    title: 'Kids Room',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/kidsroom/kids-room-6.jpeg'),
    image: require('./../../images/jsinterio/services/kidsroom/kids-room-6.jpeg'),
    title: 'Kids Room',
    filter: 'cat-3',
  },
];

class KidsBedRoom extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Kids BedRoom" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Kids BedRoom</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        Unleash your imagination and explore a plethora of interior design ideas for your kid's room with JSInteriors Company. Our expertise
                        lies in creating stylish and captivating makeovers for your little one's sanctuary, using the trendiest designs and premium materials.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/kidsroom/kids-room-4.jpeg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/kidsroom/kids-room-5.jpeg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/kidsroom/kids-room-7.jpeg')} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="mt-post-text">
                    <p>
                      Stay updated with the latest and stylish kids' room design trends for 2022, including the use of layers to create depth, experimenting
                      with vibrant colors to achieve a playful ambience, introducing floral designs for a fresh and nature-inspired look, and incorporating
                      wooden elements for a streamlined and timeless appeal.
                    </p>
                    <h4>
                      "With our affordable prices and guidance from our experts, we ensure a delightful and personalized transformation that exceeds your
                      expectations."
                    </h4>
                    <p>
                      {' '}
                      Take your kid's room design to the next level with exciting decor accessories, such as modern art to inspire their creativity, global maps
                      to foster learning and exploration, wild animal-themed accessories for a trendy touch, and vintage cool elements like cushions, rugs, wall
                      art, and lighting to add charm and character.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        We understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your satisfaction.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      Discover the best color combination ideas for your kid's room interior, such as the refreshing combination of green and white, the
                      harmonious blend of greys and neutrals, the timeless pairing of white and deep blue, and the captivating combination of lilac purple and
                      white.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <h4>
                      The selection of colors, textures, and decorative elements contributes to the overall atmosphere, evoking a sense of tranquility and
                      comfort.{' '}
                    </h4>
                    <p>
                      Explore different types of kids' beds in India, including bunk beds for maximizing space and adding an element of fun, loft beds for
                      creating extra storage and versatility, and single beds for their functionality and timeless appeal.
                    </p>
                    <p>
                      Design a captivating and enchanting space for your little one's dreams to flourish with Interior Company's exceptional expertise and
                      affordable prices. Let us bring their imagination to life and create a magical haven they'll adore.
                    </p>

                    <h4>
                      "Design a captivating and enchanting space for your little one's dreams to flourish with JSInteriors Company exceptional expertise and
                      affordable prices. Let us bring their imagination to life and create a magical haven they'll adore."
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>
        <Footer />
      </>
    );
  }
}

export default KidsBedRoom;
