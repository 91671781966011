import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-1.jpg'),
    image: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-1.jpg'),
    title: 'Master Bedroom',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-2.jpg'),
    image: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-2.jpg'),
    title: 'Master Bedroom',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-3.jpg'),
    image: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-3.jpg'),
    title: 'Master Bedroom',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-4.jpg'),
    image: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-4.jpg'),
    title: 'Master Bedroom',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-5.jpg'),
    image: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-5.jpg'),
    title: 'Master Bedroom',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-6.jpg'),
    image: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-6.jpg'),
    title: 'Master Bedroom',
    filter: 'cat-3',
  },
];

const block3 = [
  {
    thumb: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-7.jpg'),
    image: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-7.jpg'),
    title: 'Master Bedroom',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-8.jpg'),
    image: require('./../../images/jsinterio/services/masterbedroom/master-bedroom-8.jpg'),
    title: 'Master Bedroom',
    filter: 'cat-2',
  },
];

class MasterBedRoom extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Master Bedroom" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Master Bedroom</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        Discover a plethora of interior design ideas for your master bedroom at Interior Company. We specialize in creating modern bedroom
                        designs that are not only unique and avant-garde but also personalized to your individual style. Our designs adhere to the principles of
                        modernity and functionality, bringing out the best aesthetic appeal in a practical manner.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/masterbedroom/master-bedroom-5.jpg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/masterbedroom/master-bedroom-6.jpg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/masterbedroom/master-bedroom-7.jpg')} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
                    <div className="container">
                      {/* IMAGE CAROUSEL START */}
                      <div className="section-content">
                        <div className="section-head">
                          <div className="mt-separator-outer separator-center">
                            <h2 className="text-uppercase sep-line-one ">
                              <span className="font-weight-300 text-primary">What to lookout</span>
                              <span className="font-weight-300 text-dark"> for desired master bedroom</span>
                            </h2>
                          </div>
                        </div>
                        <div className="text-center">
                          <div key={1} className="col-md-3 col-sm-4">
                            <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-dark">
                              <div className="icon-content">
                                <h4 className="mt-tilte text-uppercase font-weight-600 m-b20 text-primary">Assess the Layout</h4>
                                <p className="text-primary">
                                  Consider the layout of your room, including carpet area and ceilings, especially when dealing with a small bedroom.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <div key={2} className="col-md-3 col-sm-4">
                            <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-dark">
                              <div className="icon-content">
                                <h4 className="mt-tilte text-uppercase font-weight-600 m-b20 text-primary">Choose the Right Color</h4>
                                <p className="text-primary">
                                  Opt for a light color palette to create an illusion of spaciousness and set the desired ambiance for your master bedroom.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <div key={3} className="col-md-3 col-sm-4">
                            <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-dark">
                              <div className="icon-content">
                                <h4 className="mt-tilte text-uppercase font-weight-600 m-b20 text-primary">Practical Furniture</h4>
                                <p className="text-primary">
                                  Select furniture that suits the shape of your bedroom, whether it's freestanding or fitted units. Experiment with eclectic
                                  styles or choose furniture that complements your master bedroom ideas.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="m-b50 text-center">
                          <div key={4} className="col-md-3 col-sm-4">
                            <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-dark">
                              <div className="icon-content">
                                <h4 className="mt-tilte text-uppercase font-weight-600 m-b20 text-primary">Choose the Right Flooring</h4>
                                <p className="text-primary">
                                  Enhance your bedroom design with a warm and inviting carpet or opt for hardwood flooring to bring a touch of nature into the
                                  space.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-post-text">
                    <p>
                      Stay updated with the latest bedroom design trends for 2023, such as layering textures and colors, incorporating personal art, installing
                      statement lights, adding botanical elements, and creating a clutter-free and serene atmosphere.
                    </p>
                    <h4>
                      "Our designs adhere to the principles of modernity and functionality, bringing out the best aesthetic appeal in a practical manner."
                    </h4>
                    <p>
                      {' '}
                      For small master bedrooms, explore our popular interior design ideas, including simple layouts, beds with storage, sheer patterns for a
                      spacious illusion, clutter removal techniques, and the use of mirrors to create the illusion of a larger space.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        Discover a plethora of interior design ideas for your master bedroom at Interior Company. We specialize in creating modern bedroom
                        designs that are not only unique and avant-garde but also personalized to your individual style.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      Transform your master bedroom with multifarious ideas, including custom-built furniture, captivating views, strategically positioned and
                      designed wardrobes, sophisticated curtains or drapes, avant-garde floor rugs, accent chairs or a loveseat, and layered lighting for a
                      harmonious and sensual ambiance.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block3.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-6 col-md-6 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h4>
                      Discover the best color scheme ideas for your master bedroom interior, such as Vastu-compliant blue, soothing beige, or refreshing green,
                      to add depth and character to your space.
                    </h4>
                    <p>
                      Design your modern bedroom with Interior Company at affordable prices. We utilize modern technology and state-of-the-art elements to
                      achieve the best bedroom designs, tailored to your budget and preferences.
                    </p>
                    <h4>
                      "Experience the perfect blend of style, comfort, and functionality in your modern master bedroom with Interior Company's expertise and
                      attention to detail."
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default MasterBedRoom;
