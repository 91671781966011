import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import GoogleMapReact from 'google-map-react';

var bnrimg = require('./../../images/banner/4.jpg');

const AnyReactComponent = ({ text }) => <div>{text}121212</div>;

class ContactUs extends React.Component {
  render() {
    const defaultProps = {
      center: {
        lat: 12.8284065,
        lng: 78.7343478,
      },
      zoom: 12,
    };
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner title="Feel free to contact us anytime" pagename="Contact Us" bgimage={bnrimg} />

          {/* SECTION CONTENTG START */}
          <div className="section-full p-tb80 inner-page-padding">
            {/* LOCATION BLOCK*/}
            <div className="container">
              {/* GOOGLE MAP & CONTACT FORM */}
              <div className="section-content">
                {/* CONTACT FORM*/}
                <div className="row">
                  <div className="col-md-10 col-sm-12">
                    <div className="contact-info m-b30">
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="mt-separator-outer separator-left">
                          <div className="mt-separator">
                            <h2 className="text-uppercase sep-line-one ">
                              <span className="font-weight-300 text-primary">Contact</span> Info
                            </h2>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <h4>To discuss your interior design needs or schedule a consultation, please contact us via</h4>
                      <div className="bg-white p-a20 text-dark">
                        <div className="mt-icon-box-wraper left p-b40">
                          <div className="icon-xs">
                            <i className="fa fa-phone" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">Phone number</h5>
                            <p>(+91) 960-605-7771</p>
                            <p>(+91) 960-605-7772</p>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper left p-b40">
                          <div className="icon-xs">
                            <i className="fa fa-envelope" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">Email address</h5>
                            <p>sales@jsinterio.com</p>
                            <p>govindareddy@jsinterio.com</p>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper left">
                          <div className="icon-xs">
                            <i className="fa fa-map-marker" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">Address info</h5>
                            <p>
                              JS Interiors,
                              <br />
                              Sy. No. 51,
                              <br />
                              New No. 51/1A,
                              <br />
                              Halehalli Village,
                              <br />
                              Bidarahalli Hobli,
                              <br />
                              Bengaluru Urban,
                              <br />
                              Karnataka. 560049
                            </p>
                          </div>
                        </div>
                      </div>
                      <p>
                        We eagerly look forward to the opportunity to transform your space into a captivating and inspiring environment that reflects your
                        unique style and exceeds your expectations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="gmap-outline">
                <div style={{ height: '400px', width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0' }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <AnyReactComponent lat={34.07328} lng={-118.25141} text={<i className="fa fa-map-marker" />} />
                  </GoogleMapReact>
                </div>
              </div> */}
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ContactUs;
