import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/services/diningroom/dinning-room-1.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/diningroom/dinning-room-2.jpg'),
    image: require('./../../images/jsinterio/services/diningroom/dinning-room-2.jpg'),
    title: 'Dining Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/diningroom/dinning-room-3.jpg'),
    image: require('./../../images/jsinterio/services/diningroom/dinning-room-3.jpg'),
    title: 'Dining Room',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/diningroom/dinning-room-4.jpg'),
    image: require('./../../images/jsinterio/services/diningroom/dinning-room-4.jpg'),
    title: 'Dining Room',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/diningroom/dinning-room-5.jpg'),
    image: require('./../../images/jsinterio/services/diningroom/dinning-room-5.jpg'),
    title: 'Dining Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/diningroom/dinning-room-6.jpg'),
    image: require('./../../images/jsinterio/services/diningroom/dinning-room-6.jpg'),
    title: 'Dining Room',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/diningroom/dinning-room-7.jpg'),
    image: require('./../../images/jsinterio/services/diningroom/dinning-room-7.jpg'),
    title: 'Dining Room',
    filter: 'cat-3',
  },
];

class DiningRoom extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Dining Room" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Dining Room</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        A dining room is a designated space within a home or establishment that holds significant importance as a gathering place where people
                        come together to enjoy meals, fostering connection and creating cherished memories. It serves as a central hub, providing a dedicated
                        environment for individuals to engage in the ritual of sharing food and conversation. At its core, the dining room typically includes
                        essential elements such as a dining table and chairs, forming the foundation for communal dining experiences.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/diningroom/dinning-room-1.jpg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/diningroom/dinning-room-2.jpg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/diningroom/dinning-room-3.jpg')} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="mt-post-text">
                    <p>
                      The dining table takes center stage in the room, serving as the focal point where meals are enjoyed and conversations flow. Its selection
                      is based on factors like available space, desired seating capacity, and personal style preferences. The dining table can be crafted from a
                      variety of materials such as wood, glass, or metal, and comes in different shapes and sizes to accommodate various spatial and design
                      requirements. The chairs surrounding the table are carefully chosen to provide both comfort and style, ranging from upholstered seats to
                      wooden or metal chairs with cushioned padding.
                    </p>
                    <h4>
                      "The dining table takes center stage in the room, serving as the focal point where meals are enjoyed and conversations flow. Its selection
                      is based on factors like available space, desired seating capacity, and personal style preferences."
                    </h4>
                    <p>
                      {' '}
                      Beyond the dining table and chairs, the dining room often incorporates additional furniture pieces that serve practical and aesthetic
                      purposes. Buffets, sideboards, or china cabinets offer valuable storage space for linens, serving utensils, and tableware, while also
                      providing a surface for setting up buffet-style meals or displaying decorative items. These furniture pieces not only enhance the
                      functionality of the room but also contribute to its overall ambiance and visual appeal.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        We understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your satisfaction.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      Proper lighting is another crucial aspect of the dining room, as it helps create the desired atmosphere and sets the tone for dining
                      experiences. A chandelier or pendant light is commonly suspended above the dining table, providing focused illumination and adding an
                      elegant touch to the space. Additional lighting fixtures like wall sconces or recessed lighting may be strategically placed to create a
                      warm and inviting environment, enhancing the overall dining experience.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h4>
                      A chandelier or pendant light is commonly suspended above the dining table, providing focused illumination and adding an elegant touch to
                      the space.{' '}
                    </h4>
                    <p>
                      The dining room extends beyond its practical functions and serves as a symbolic space that fosters meaningful connections among family
                      members, friends, and guests. It offers a dedicated area where people come together to not only satisfy their hunger but also share
                      stories, laughter, and experiences. Whether it's a casual breakfast with loved ones or a formal dinner party, the dining room provides a
                      backdrop for special occasions and everyday moments alike, promoting togetherness, bonding, and the creation of lasting memories.
                    </p>

                    <h4>
                      In summary, the dining room is a cherished space within a home or establishment, serving as a designated area for people to gather and
                      enjoy meals together. Through the careful selection of dining table, chairs, lighting fixtures, and additional furniture pieces, the
                      dining room creates an ambiance that enhances the dining experience, encouraging connection and fostering meaningful relationships. It is
                      a place where conversations flow and laughter abounds.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default DiningRoom;
