import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/shoerack/shoe-rack-1.jpg'),
    image: require('./../../images/jsinterio/services/shoerack/shoe-rack-1.jpg'),
    title: 'Shoe Rack',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/shoerack/shoe-rack-2.jpg'),
    image: require('./../../images/jsinterio/services/shoerack/shoe-rack-2.jpg'),
    title: 'Shoe Rack',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/shoerack/shoe-rack-3.jpg'),
    image: require('./../../images/jsinterio/services/shoerack/shoe-rack-3.jpg'),
    title: 'Shoe Rack',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/shoerack/shoe-rack-4.jpg'),
    image: require('./../../images/jsinterio/services/shoerack/shoe-rack-4.jpg'),
    title: 'Shoe Rack',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/shoerack/shoe-rack-2.jpg'),
    image: require('./../../images/jsinterio/services/shoerack/shoe-rack-2.jpg'),
    title: 'Shoe Rack',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/shoerack/shoe-rack-1.jpg'),
    image: require('./../../images/jsinterio/services/shoerack/shoe-rack-1.jpg'),
    title: 'Shoe Rack',
    filter: 'cat-3',
  },
];

class ShoeRack extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Shoe Rack" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Shoe Rack</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        A shoe rack serves as a purpose-built furniture piece or storage solution meticulously designed to hold and organize footwear
                        efficiently. It is commonly found in entryways, mudrooms, closets, or bedrooms, strategically placed to offer convenient access to
                        shoes. Shoe racks are available in a diverse range of styles, sizes, and designs, ensuring they can accommodate varying quantities of
                        shoes and fit seamlessly into different spaces.
                      </h5>
                    </div>
                  </div>
                  <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                    {block1.map((item, index) => (
                      <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                        <div className="project-classic">
                          <div className="mt-box ">
                            <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                              <img src={item.thumb} alt="" />
                              <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <a className="mfp-link" href={item.image}>
                                    <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-post-text">
                    <p>Typically, a shoe rack consists of multiple tiers or shelves that provide dedicated compartments for individual pairs of shoes.</p>
                    <h4>
                      "Some shoe racks may also incorporate supplementary storage options like drawers or baskets, offering a convenient place to store shoe
                      accessories like polish, laces, or inserts."
                    </h4>
                    <p>
                      These compartments may be open or equipped with additional features such as doors or covers, serving the dual purpose of safeguarding
                      shoes from dust and contributing to an overall organized and visually pleasing appearance.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        We understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your satisfaction.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <p>
                      The primary objective of a shoe rack is to maintain the organization of shoes, facilitating easy accessibility while effectively utilizing
                      space and minimizing clutter.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <p>
                      By providing a designated storage area, shoe racks help protect footwear from damage caused by being stepped on or crushed, thus
                      prolonging their lifespan. Furthermore, they contribute to good hygiene by ensuring shoes are stored in a well-ventilated environment,
                      mitigating the development of unpleasant odors.
                    </p>

                    <h4>
                      When selecting a suitable shoe rack, it is essential to consider factors such as the number of shoes to be accommodated, available space
                      constraints, and individual preferences.
                    </h4>
                    <p>
                      Shoe racks encompass a broad spectrum of designs, ranging from simple and compact options to intricately decorative choices that can
                      enhance the overall aesthetics of a room while effectively fulfilling their practical purpose.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default ShoeRack;
