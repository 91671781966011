import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg');

const block1 = [
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-4.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-4.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-1',
  // },
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-5.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-5.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-2',
  // },
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-6.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-6.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-3',
  // },
];
const block2 = [
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-7.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-7.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-1',
  // },
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-8.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-8.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-2',
  // },
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-9.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-9.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-3',
  // },
];

const block3 = [
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-10.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-10.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-1',
  // },
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-11.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-11.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-2',
  // },
];

const block4 = [
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-1.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-1.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-1',
  // },
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-2.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-2.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-2',
  // },
  // {
  //   thumb: require('./../../images/jsinterio/kitchen/kitchen-3.jpeg'),
  //   image: require('./../../images/jsinterio/kitchen/kitchen-3.jpeg'),
  //   title: 'Kitchen',
  //   filter: 'cat-3',
  // },
];

class Wardrobes extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Wardrobes" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Wardrobes</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        Unlock a world of inspiration with our diverse range of wardrobe interior design ideas for your bedroom. In modern bedroom design,
                        wardrobes serve as personal spaces to keep your room organized while seamlessly blending with your bedroom decor. At Interior Company,
                        we offer a fashionable guide to modern wardrobe designs, showcasing a variety of stylish options.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">{/* <img src={require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg')} alt="" /> */}</div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">{/* <img src={require('./../../images/jsinterio/kitchen/kitchen-2.jpeg')} alt="" /> */}</div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">{/* <img src={require('./../../images/jsinterio/kitchen/kitchen-3.jpeg')} alt="" /> */}</div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="mt-post-text">
                    <p>
                      From sleek wardrobe designs with a glossy finish to L-shaped modular designs, sliding doors, glass doors, and trendy options featuring
                      digital prints, our collection encompasses the latest trends in wardrobe design for bedrooms.
                    </p>
                    <h4>
                      "With our affordable prices and guidance from our experts, we ensure a delightful and personalized transformation that exceeds your
                      expectations."
                    </h4>
                    <p>
                      {' '}
                      Our aesthetically pleasing and functional wardrobe designs combine beauty and utility, providing the perfect storage solution for your
                      belongings. Whether you desire the illusion of openness with glass shutters or seek space-saving solutions with sliding panel wardrobes,
                      our designs cater to different preferences and bedroom sizes.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        We understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your satisfaction.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      Discover various types of wardrobe designs, including freestanding wardrobes, walk-in wardrobes, laminated wardrobes, hinged door
                      wardrobes, sliding door wardrobes, integrated vanity wardrobes, and integrated TV unit wardrobes. Before making a purchase, consider
                      factors such as your bedroom's layout, storage needs, wardrobe style, material selection, and budget.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block3.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-6 col-md-6 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h4>
                      The selection of colors, textures, and decorative elements contributes to the overall atmosphere, evoking a sense of tranquility and
                      comfort.{' '}
                    </h4>
                    <p>
                      Stay up to date with the latest wardrobe design trends for master bedrooms in 2023 by exploring our catalog. Our expert advice and styling
                      tips will assist you in selecting the perfect wardrobe that seamlessly integrates into your home.
                    </p>
                    <p>
                      Additionally, we provide exciting wardrobe color combinations, with light neutral colors being popular for creating a spacious and stylish
                      ambiance in Indian bedrooms.
                    </p>
                    <p>
                      Design your modern wardrobe with us at affordable prices. With a wide variety of styles, sizes, and materials available, including
                      regular, sliding, and folding doors, our versatile and aesthetically pleasing wardrobe designs cater to diverse preferences. Connect with
                      our Interior Company team to receive expert guidance and choose the ideal wardrobe for your home.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block4.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="m-b0">
                      <div className="section-head">
                        <div className="mt-separator-outer separator-left">
                          <div className="mt-separator">
                            <h2 className="text-black text-uppercase sep-line-one ">
                              <span className="font-weight-300 text-primary">FAQs</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="section-content">
                        <div className="m-b50">
                          <ol className="list-num-count upper-alpha list-outline">
                            <li>How do you design an interior wardrobe?</li>
                            <li>Which type of wardrobe is best for the bedroom?</li>
                            <li>What is the minimum wardrobe depth?</li>
                            <li>What is the best material to build a wardrobe?</li>
                            <li>How much does an interior wardrobe cost?</li>
                            <li>What is the difference between a closet and a wardrobe?</li>
                            <li>What can you put in cupboards to prevent mold?</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                    <h4>
                      "We are here to answer these questions and provide you with comprehensive solutions that meet your specific needs. Experience the perfect
                      blend of style and functionality with our exceptional wardrobe designs."
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Wardrobes;
