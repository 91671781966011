import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/banner/1.jpg');
const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-4.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-4.jpeg'),
    title: 'Kitchen',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-5.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-5.jpeg'),
    title: 'Kitchen',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-6.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-6.jpeg'),
    title: 'Kitchen',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-7.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-7.jpeg'),
    title: 'Kitchen',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-8.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-8.jpeg'),
    title: 'Kitchen',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-9.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-9.jpeg'),
    title: 'Kitchen',
    filter: 'cat-3',
  },
];

const block3 = [
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-10.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-10.jpeg'),
    title: 'Kitchen',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-11.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-11.jpeg'),
    title: 'Kitchen',
    filter: 'cat-2',
  },
];

const block4 = [
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-1.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-1.jpeg'),
    title: 'Kitchen',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-2.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-2.jpeg'),
    title: 'Kitchen',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/kitchen/kitchen-3.jpeg'),
    image: require('./../../images/jsinterio/services/kitchen/kitchen-3.jpeg'),
    title: 'Kitchen',
    filter: 'cat-3',
  },
];

class KitchenListing extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Kitchen" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Kitchen</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        Immerse yourself in a world of stylish and innovative modular kitchens that will revolutionize the focal point of your home. Indulge in
                        our vast array of kitchen design ideas, inspiring makeovers, and expert tips, guiding you towards the impeccable combination of
                        cabinetry, countertops, and appliances that will truly elevate your space. Our tailored modern kitchen designs offer unrivaled
                        flexibility, convenience, and functionality, accommodating your unique creative vision and design preferences. Whether you lean towards
                        the efficiency of an L-shaped layout or the sleekness of an open kitchen interior design, delve into the latest trends and discover how
                        to craft a culinary haven that perfectly aligns with your needs.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/kitchen/kitchen-1.jpeg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/kitchen/kitchen-2.jpeg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/kitchen/kitchen-3.jpeg')} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="mt-post-text">
                    <p>
                      Experience the epitome of comfort and luxury with our wide selection of modular kitchen designs, which have become a popular choice among
                      homemakers. Recognizing the significance of a well-organized kitchen as the heart of the home, we offer Indian modular kitchen designs
                      that cater to diverse functional needs and budgets.
                    </p>
                    <h4>"With careful planning, expert advice, and an abundance of inspiration, you can bring your dream modular kitchen to life."</h4>
                    <p>
                      {' '}
                      Modular kitchens bring forth a host of benefits, such as optimizing functionality and seamlessly expanding storage systems. They come in
                      various styles, accompanied by innovative accessories that promote a clutter-free environment in your kitchen. Our modular kitchen
                      catalogues feature an extensive range of colors, sizes, and materials that can be effortlessly customized to meet your requirements.
                      Moreover, modular kitchens offer flexibility, allowing for easy remodeling to keep up with changing times.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        The prefabricated nature of modular kitchen systems allows for quick and cost-effective installation, supported by our experts' guidance
                        and recommendations to enhance your design.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div>
                    </blockquote>
                    <p>
                      To design your desired modular kitchen, our team of skilled designers at Interior Company offers tailored solutions. Utilizing the latest
                      techniques and precision, we create stunning modular kitchens that perfectly complement your home. Begin by considering your current
                      kitchen situation, size, and budget, then choose a design style that resonates with your preferences. Explore different kitchen layouts
                      and plan accordingly, considering the ideal countertop length and the concept of a work triangle (cooking stove, sink, and refrigerator).
                      Select the appropriate cabinetry, flooring, and finishes, and personalize your kitchen with accessories, appliances, and fittings.{' '}
                    </p>

                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      Discover the latest modular kitchen interior design ideas through our comprehensive catalogue, keeping you in touch with the current
                      trends in contemporary urban settings. From the practicality of an Island modular kitchen design to the efficiency of an L-shaped or
                      parallel layout, and from wooden modern designs to minimalistic and elegant options, we offer a wide range of choices to suit your taste.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <p>
                      Looking ahead to 2023, we anticipate several popular modular kitchen design trends that will elevate your kitchen space. Embrace
                      tech-forward contemporary styles, incorporate nature-inspired natural materials, experiment with colored cabinets, introduce marble
                      elements, and consider mirrored accents to add a touch of glamour to your kitchen interior.
                    </p>
                    <p>
                      At Interior Company, we understand the essential components of a modular kitchen. From the ideal countertop to smart appliances, base and
                      wall cabinets, and a variety of add-on accessories, we ensure a seamless cooking experience with our Indian modular kitchens.
                    </p>
                    <p>
                      Choose Interior Company as your partner for designing your modular kitchen. Our tailored solutions and expertise will guide you in
                      selecting the best modular kitchen interior that suits your modern home. We follow a comprehensive process to deliver exceptional results,
                      providing you with a range of options in layouts, materials, and costs.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block3.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-6 col-md-6 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      We believe that a budget should not hinder your dream kitchen. Even with cost constraints, we offer trendy modern modular kitchen designs
                      that are affordable yet stylish, without compromising on functionality and aesthetics.
                    </p>
                    <h4>
                      Explore the latest trends in modular kitchens, learn about different types of layouts, understand the installation process, estimate the
                      time required for assembly, and discover the advantages of modular kitchens over traditional ones.In a modular kitchen, every inch of
                      space is maximized through the use of modules comprising drawers, cabinets, and shelves, ensuring an organized and efficient kitchen
                      space.
                    </h4>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block4.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h4>"With careful planning, expert advice, and an abundance of inspiration, you can bring your dream modular kitchen to life."</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default KitchenListing;
