import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import AboutSummary from './../Elements/AboutSummary';

var bnrimg = require('./../../images/banner/1.jpg');

class AboutInfrastructure extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner title="COMMITTED TO SUPERIOR INTERIOR WORKS." pagename="About Infrastructure" bgimage={bnrimg} />
          <AboutSummary />
        </div>
        <Footer />
      </>
    );
  }
}

export default AboutInfrastructure;
