import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/foyer/foyer-1.png'),
    image: require('./../../images/jsinterio/services/foyer/foyer-1.png'),
    title: 'Foyer Unit',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/foyer/foyer-2.jpeg'),
    image: require('./../../images/jsinterio/services/foyer/foyer-2.jpeg'),
    title: 'Foyer Unit',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/foyer/foyer-3.jpeg'),
    image: require('./../../images/jsinterio/services/foyer/foyer-3.jpeg'),
    title: 'Foyer Unit',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/foyer/foyer-4.jpeg'),
    image: require('./../../images/jsinterio/services/foyer/foyer-4.jpeg'),
    title: 'Foyer Unit',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/foyer/foyer-5.jpeg'),
    image: require('./../../images/jsinterio/services/foyer/foyer-5.jpeg'),
    title: 'Foyer Unit',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/foyer/foyer-6.jpeg'),
    image: require('./../../images/jsinterio/services/foyer/foyer-6.jpeg'),
    title: 'Foyer Unit',
    filter: 'cat-3',
  },
];

class FoyerUnit extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Foyer Unit" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Foyer Unit</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        A foyer unit is a specialized furniture piece or storage system designed specifically to enhance and organize the entryway or foyer area
                        of a home or building. The foyer, also known as the entryway or entrance hall, is the space immediately inside the front door that
                        warmly welcomes people into the interior.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/foyer/foyer-2.jpeg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/foyer/foyer-3.jpeg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/foyer/foyer-7.jpeg')} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="mt-post-text">
                    <p>
                      Meticulously crafted for both practical functionality and aesthetic appeal, a foyer unit seamlessly combines various features to create an
                      exceptionally organized and visually pleasing area.
                    </p>
                    <h4>
                      "Foyer units are available in a wide variety of designs and configurations, ranging from freestanding furniture pieces to built-in wall
                      units or customizable solutions."
                    </h4>
                    <p>
                      It typically incorporates essential elements such as a comfortable bench or seating area, offering a convenient spot for putting on or
                      taking off shoes. Alongside, it provides storage compartments or shelves meticulously designed to hold items like shoes, umbrellas, or
                      keys in a neat and accessible manner. Some foyer units even include a mirror, allowing for quick last-minute checks before heading out the
                      door.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        We understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your satisfaction.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      Foyer units are available in a wide variety of designs and configurations, ranging from freestanding furniture pieces to built-in wall
                      units or customizable solutions.They offer flexibility in terms of different sizes, styles, and materials to perfectly complement various
                      architectural styles and cater to individual preferences.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <p>
                      These units serve a vital practical purpose by efficiently promoting organization and effectively reducing clutter in the entryway. By
                      providing a designated space to store essentials, they ensure a sense of tidiness and contribute to creating a warm and inviting
                      atmosphere for both residents and guests.
                    </p>

                    <h4>
                      Foyer units have the remarkable ability to serve as captivating design focal points, infusing the entrance area of a home or building with
                      an exceptional touch of style and personality.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default FoyerUnit;
