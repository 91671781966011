import React from 'react';

var img1 = require('./../../images/background/bg-6.png');
var img2 = require('./../../images/background/line.png');

class AboutSummary extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b80 bg-dark bg-repeat square_shape2 inner-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-left">
                <div className="mt-separator text-white">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">About</span> Infrastructure
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content our-story">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img src={require('./../../images/jsinterio/about/infra-1.jpeg')} alt="WOODTECH COLD PRESS" />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                    <h4 className="m-b20">WOODTECH COLD PRESS</h4>
                    <p>
                      The Woodtech cold press machine is designed to apply uniform pressure to wood panels, veneers, laminates, and other wood-based materials.
                      It is commonly used in the manufacturing of furniture, cabinetry, doors, and other woodworking applications. The cold press machine
                      typically consists of a sturdy steel frame with a hydraulic system for applying pressure. It features a large flat surface or platen where
                      the wood material is placed. The hydraulic system is used to exert pressure on the wood, compressing it and allowing adhesives to bond
                      properly. Cold pressing is often used in conjunction with glues or adhesives to create strong and durable wood products. The absence of
                      heat in the pressing process helps minimize the risk of wood deformation, discoloration, or damage caused by high temperatures.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img src={require('./../../images/jsinterio/about/infra-2.jpeg')} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                    {/* <h4 className="text-uppercase m-b20">2013-14</h4> */}
                    <h4 className="m-b20">PANEL SAW (ALTENDORF WA8)</h4>
                    <p>
                      The Altendorf WA8 is designed to make precise and accurate cuts in various types of wood and wood-based materials. It is commonly used in
                      the furniture industry, cabinet making, and woodworking workshops. Key features of the Altendorf WA8 machine may include:
                      <br />
                      1. Sliding Table: The WA8 is equipped with a large sliding table that allows for smooth and controlled movement of the workpiece during
                      cutting. The table is adjustable and provides stability and precision.
                      <br />
                      2. Saw Blade: The machine features a powerful saw blade that is mounted on an arbor and can be adjusted for different cutting angles and
                      depths. The blade is designed to make clean and precise cuts.
                      <br />
                      3. Fence System: The WA8 typically includes a rip fence system that allows for accurate and parallel cuts. The fence can be adjusted and
                      locked into position, ensuring consistent and repeatable cuts.
                      <br />
                      4. Safety Features: Altendorf machines, including the WA8, prioritize safety. They often come with features such as blade guards,
                      emergency stop buttons, and safety sensors to protect operators from potential hazards.
                      <br />
                      5. Control Panel: The machine may have a user-friendly control panel that allows operators to adjust settings such as blade height, angle,
                      and cutting speed.
                      <br />
                      6. Dust Collection: Woodworking generates a significant amount of sawdust, and the WA8 may have a built-in dust collection system or port
                      to help keep the workspace clean.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img src={require('./../../images/jsinterio/about/infra-3.jpeg')} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                    {/* <h4 className="text-uppercase m-b20">2015-16</h4> */}
                    <h4 className="m-b20">Edge Banding Machine (KIOT665J) </h4>
                    <p>
                      KIOT 665J edge banding involves applying a strip of material, often made of PVC or wood veneer, to the edges of a wood panel to give it a
                      finished look and to protect the edges from wear and tear. Here are some general features and functionalities typically found in this edge
                      banding machine:
                      <br />
                      1. Edge Band Application: The machine is designed to apply edge bands to the edges of wood panels. It typically features a feed system
                      that transports the panels through the machine while the edge band material is applied.
                      <br />
                      2. Glue Application: The edge banding machine may have a glue application system that applies adhesive to the edge band or the edge of the
                      panel. This ensures proper adhesion between the panel and the edge banding material.
                      <br />
                      3. Trimming Mechanism: The machine often includes a trimming mechanism, which trims the excess edge band material from the top and bottom
                      surfaces of the panel. It helps create clean and flush edges.
                      <br />
                      4. Edge Finishing: Some edge banding machines have additional features for edge finishing, such as scraping, buffing, or sanding, to
                      achieve a smooth and polished edge after trimming.
                      <br />
                      5. Control Panel: The machine may have a control panel that allows operators to adjust settings such as feed speed, glue application,
                      trimming depth, and other parameters.
                      <br />
                      6. Safety Features: Edge banding machines typically come with safety features, such as emergency stop buttons, blade guards, and sensors
                      to ensure safe operation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img src={require('./../../images/jsinterio/about/infra-4.jpeg')} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div className="mt-box our-story-detail  bg-cover" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                    {/* <h4 className="text-uppercase m-b20">2017-18</h4> */}
                    <h4 className="m-b20">Minifix Boring Machine (KIOT553)</h4>
                    <p>
                      KIOT 533 machine is designed for drilling holes for the Minifix system. The Minifix system is a type of connector commonly used in
                      furniture assembly, allowing for quick and easy joining of panels without the need for traditional screws or dowels. The Kiot 533 Minifix
                      Boring Machine is specifically designed to drill holes for the Minifix connectors. Here are some general features and functionalities
                      found in this Minifix boring machine:
                      <br />
                      1. Hole Drilling: The machine is equipped with drilling spindles or heads that are positioned to drill precise holes in the wood panels.
                      These holes are typically used for inserting the Minifix connectors.
                      <br />
                      2. Adjustable Hole Patterns: The machine may have adjustable settings for hole spacing and positioning to accommodate different panel
                      sizes and requirements.
                      <br />
                      3. Depth Control: The machine allows for adjustable drilling depth to ensure the holes are drilled to the appropriate depth for inserting
                      the Minifix connectors securely.
                      <br />
                      4. Pneumatic or Manual Operation: The machine can be operated using pneumatic power or manually, depending on the model and design.
                      Pneumatic operation typically offers faster and more efficient drilling.
                      <br />
                      5. Workpiece Clamping: The machine may include a clamping mechanism or workpiece support system to hold the wood panels in place during
                      drilling, ensuring stability and accuracy.
                      <br />
                      6. Control Panel: Some Minifix boring machines may have a control panel that allows operators to adjust drilling parameters and settings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutSummary;
