import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/poojaunit/pooja-unit-1.jpg'),
    image: require('./../../images/jsinterio/services/poojaunit/pooja-unit-1.jpg'),
    title: 'Pooja unit',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/poojaunit/pooja-unit-2.jpg'),
    image: require('./../../images/jsinterio/services/poojaunit/pooja-unit-2.jpg'),
    title: 'Pooja unit',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/poojaunit/pooja-unit-3.jpg'),
    image: require('./../../images/jsinterio/services/poojaunit/pooja-unit-3.jpg'),
    title: 'Pooja unit',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/poojaunit/pooja-unit-4.jpg'),
    image: require('./../../images/jsinterio/services/poojaunit/pooja-unit-4.jpg'),
    title: 'Pooja unit',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/poojaunit/pooja-unit-2.jpg'),
    image: require('./../../images/jsinterio/services/poojaunit/pooja-unit-2.jpg'),
    title: 'Pooja unit',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/poojaunit/pooja-unit-3.jpg'),
    image: require('./../../images/jsinterio/services/poojaunit/pooja-unit-3.jpg'),
    title: 'Pooja unit',
    filter: 'cat-3',
  },
];

class PoojaUnits extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Pooja Units" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Pooja Units</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        A modern pooja unit represents a thoughtfully crafted and visually appealing area or furniture designed explicitly to accommodate a
                        pooja space within a contemporary home. These units skillfully merge traditional elements with modern design principles, resulting in a
                        functional and aesthetically pleasing environment for prayer and meditation.
                      </h5>
                    </div>
                  </div>
                  <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                    {block1.map((item, index) => (
                      <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                        <div className="project-classic">
                          <div className="mt-box ">
                            <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                              <img src={item.thumb} alt="" />
                              <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <a className="mfp-link" href={item.image}>
                                    <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-post-text">
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <h2 className="text-white text-uppercase sep-line-one ">
                          <span className="font-weight-300 text-primary">Here are some key features commonly found in modern pooja units</span>
                        </h2>
                      </div>
                    </div>
                    <h4>Compact and Space-saving</h4>
                    <p>
                      Modern pooja units are intelligently designed to fit seamlessly into smaller spaces, making them ideal for apartments or homes with
                      limited room. Their compact size ensures efficient utilization of the available space without compromising functionality.
                    </p>
                    <h4>Modular Design</h4>
                    <p>
                      Many modern pooja units adopt a modular approach, offering customization options based on individual preferences and available space.
                      These units consist of different modules or sections that can be arranged or combined to suit specific requirements, providing flexibility
                      and adaptability.
                    </p>
                    <h4>Sleek and Minimalist</h4>
                    <p>
                      Modern pooja units often exhibit sleek lines and minimalist designs, incorporating clean aesthetics and contemporary materials such as
                      wood, glass, metal, or even acrylic. The emphasis is on simplicity and elegance, creating a visually appealing focal point within the
                      overall home decor.
                    </p>
                    <h4>Lighting</h4>
                    <p>
                      Adequate lighting is a crucial aspect of a pooja unit. Modern units integrate ambient lighting, spotlights, or LED lights strategically to
                      illuminate the idols or sacred objects placed within the unit. These lighting elements enhance the overall visual appeal and contribute to
                      a serene ambiance during prayer.
                    </p>
                    <h4>Storage Solutions</h4>
                    <p>
                      Many modern pooja units feature built-in storage options to maintain the organization of religious books, accessories, and other related
                      items. These storage compartments are seamlessly integrated into the unit's design, allowing easy access and ensuring a clutter-free
                      space.
                    </p>
                    <h4>Accessibility and Convenience</h4>
                    <p>
                      Modern pooja units prioritize accessibility, ensuring convenient reach to the idols or sacred objects placed within them. They may
                      incorporate sliding or folding doors, pull-out trays, or adjustable shelves, enabling users to perform rituals comfortably and maintain
                      the pooja space with ease.
                    </p>
                    <h4>Integration with Home Decor</h4>
                    <p>
                      Modern pooja units are meticulously designed to seamlessly blend with the overall interior design of the house. They can be customized to
                      match the color scheme, materials, and style of the surrounding decor, harmoniously integrating the pooja space as an integral part of the
                      home's aesthetic.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        We understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your satisfaction.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <p>
                      In essence, modern pooja units provide a contemporary interpretation of a traditional pooja room, offering a perfect balance of
                      functionality, style, and spiritual significance.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <p>
                      These units cater to the needs and preferences of individuals seeking a modern approach to incorporating a dedicated prayer space in their
                      homes, symbolizing a harmonious coexistence of tradition and modernity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default PoojaUnits;
