import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-1.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-1.jpg'),
    title: 'Guest Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-2.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-2.jpg'),
    title: 'Guest Room',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-3.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-3.jpg'),
    title: 'Guest Room',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-4.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-4.jpg'),
    title: 'Guest Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-5.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-5.jpg'),
    title: 'Guest Room',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-6.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-6.jpg'),
    title: 'Guest Room',
    filter: 'cat-3',
  },
];

const block3 = [
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-7.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-7.jpg'),
    title: 'Guest Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-8.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-8.jpg'),
    title: 'Guest Room',
    filter: 'cat-2',
  },
];

const block4 = [
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-9.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-9.jpg'),
    title: 'Guest Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-10.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-10.jpg'),
    title: 'Guest Room',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/guestroom/guest-room-5.jpg'),
    image: require('./../../images/jsinterio/services/guestroom/guest-room-5.jpg'),
    title: 'Guest Room',
    filter: 'cat-3',
  },
];

class GuestBedRoom extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Guest Bedroom" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Guest Bedroom</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        Guest rooms play a vital role in accommodating visitors and overnight guests. These specially designated spaces are meticulously
                        designed and arranged to cater to the needs and desires of individuals seeking a temporary retreat. With careful attention to detail,
                        guest rooms provide a private sanctuary where guests can unwind, find solace, and securely store their personal belongings. This essay
                        explores the elements that make guest rooms truly enchanting, from their cozy beds and luxurious linens to the thoughtful inclusion of
                        furniture and amenities that enhance comfort, privacy, and overall satisfaction.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/guestroom/guest-room-1.jpg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/guestroom/guest-room-2.jpg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/guestroom/guest-room-3.jpg')} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="mt-post-text">
                    <p>
                      One of the fundamental features of any guest room is a cozy bed adorned with luxurious linens. By investing in high-quality mattresses and
                      bedding, guest room designers ensure that visitors enjoy a rejuvenating slumber. Soft, plush pillows and cozy blankets create an inviting
                      atmosphere, allowing guests to find solace and tranquility during their stay.
                    </p>
                    <h4>"Soft,plush pillows & cozy blankets create an inviting atmosphere, allowing guests to find solace & tranquility during their stay."</h4>
                    <p>
                      {' '}
                      Guest rooms often incorporate well-placed furniture to maximize functionality and convenience. Bedside tables, equipped with carefully
                      positioned lamps, provide essential lighting and serve as convenient surfaces for personal items such as books, glasses, or mobile
                      devices.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        A dresser or wardrobe offers ample storage space for guests' belongings, allowing them to maintain a tidy and organized environment.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      A dresser or wardrobe offers ample storage space for guests' belongings, allowing them to maintain a tidy and organized environment.
                      Additionally, a desk and chair create a dedicated area for work, study, or leisure activities, ensuring guests have a comfortable space to
                      pursue their interests.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block3.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-6 col-md-6 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h4>Guest rooms also provide amenities that enhance the comfort and convenience of the guests.</h4>
                    <p>
                      A television allows visitors to unwind and catch up on their favorite shows, while a mini-fridge or coffee maker offers easy access to
                      refreshments. These additional amenities ensure that guests feel pampered and well-cared for, making their stay even more memorable and
                      enjoyable.
                    </p>
                    <p>
                      The primary objective of a guest room is to create a warm and inviting ambiance. Through meticulous curation of aesthetics and
                      functionality, guest room designers strive to make visitors feel embraced and at ease.
                    </p>
                    <p>
                      The selection of colors, textures, and decorative elements contributes to the overall atmosphere, evoking a sense of tranquility and
                      comfort. Personal touches like artwork or photographs can also add a touch of warmth and familiarity, making guests feel more connected to
                      their surroundings.
                    </p>
                    <p>
                      In conclusion, guest rooms serve as comforting retreats for visitors, providing a space where they can relax, rejuvenate, and feel at
                      home. With their cozy beds, luxurious linens, well-placed furniture, and thoughtful amenities, these rooms are designed to prioritize the
                      comfort, privacy, and satisfaction of guests. By creating an enchanting atmosphere and attending to the smallest details, guest rooms
                      ensure that every visitor's stay is enjoyable, memorable, and truly unforgettable.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block4.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h4>
                      "The primary objective of a guest room is to create a warm and inviting ambiance. Through meticulous curation of aesthetics and
                      functionality, guest room designers strive to make visitors feel embraced and at ease."
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>
        <Footer />
      </>
    );
  }
}

export default GuestBedRoom;
