import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-1.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-1.jpg'),
    title: 'Living Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-2.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-2.jpg'),
    title: 'Living Room',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-3.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-3.jpg'),
    title: 'Living Room',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-4.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-4.jpg'),
    title: 'Living Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-5.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-5.jpg'),
    title: 'Living Room',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-6.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-6.jpg'),
    title: 'Living Room',
    filter: 'cat-3',
  },
];

const block3 = [
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-7.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-7.jpg'),
    title: 'Living Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-8.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-8.jpg'),
    title: 'Living Room',
    filter: 'cat-2',
  },
];

const block4 = [
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-9.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-9.jpg'),
    title: 'Living Room',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-10.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-10.jpg'),
    title: 'Living Room',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/livingroom/living-room-11.jpg'),
    image: require('./../../images/jsinterio/services/livingroom/living-room-11.jpg'),
    title: 'Living Room',
    filter: 'cat-3',
  },
];

class LivingRoom extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Living Room" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Living Room</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        Elevate your living room design with JS Interiors vast array of interior design ideas, encompassing timeless and chic designs that
                        transcend fleeting trends. By focusing on mastering different themes and capturing the essence of Indian living room ideas, we are
                        dedicated to bringing out the best in your space.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/livingroom/living-room-12.jpg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/livingroom/living-room-13.jpg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/livingroom/living-room-14.jpg')} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="mt-post-text">
                    <p>
                      From selecting a style and layout that aligns with your personal taste, to choosing an appropriate color palette that attracts positive
                      energy, our expert designers will guide you through every step.
                    </p>
                    <h4>"With a keen eye for durable and timeless furniture pieces, we ensure your living room remains stylish for years to come."</h4>
                    <p>
                      {' '}
                      Proper lighting is key, and we will help you incorporate both functional and mood lighting to set the perfect ambiance. Stay up-to-date
                      with the latest trends for 2023 living room designs, including maximizing space with mirrors, incorporating decorated storage,
                      experimenting with unexpected color combinations, and adding patterns for a chic and classy look.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        We understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your satisfaction.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      Even if you have a small living room, our design ideas will help you make the most of the space, with minimalistic approaches, creative
                      storage solutions, and clever use of mirrors to create an illusion of a larger area.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block3.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-6 col-md-6 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h4>
                      The selection of colors, textures, and decorative elements contributes to the overall atmosphere, evoking a sense of tranquility and
                      comfort.{' '}
                    </h4>
                    <p>
                      Choose from our curated color schemes, ranging from soothing shades of green to blush and sky blue, to light grey and pastel blues, among
                      others. Enhance your living room design with captivating wall decoration items, such as mirrors, fabric showcases, large canvas paintings,
                      LED wall art, murals, metal wall arts, planter wall decorations, hanging plates, or curated wall galleries.
                    </p>
                    <p>
                      At JS Interiors, we understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your
                      satisfaction.
                    </p>
                    <p>
                      The selection of colors, textures, and decorative elements contributes to the overall atmosphere, evoking a sense of tranquility and
                      comfort. Personal touches like artwork or photographs can also add a touch of warmth and familiarity, making guests feel more connected to
                      their surroundings.
                    </p>
                    <p>
                      Explore our FAQs section for answers to common questions about interior living room planning, different styles of living rooms, trending
                      Indian living room designs, and more. With Our expertise, elevate your living room interior and transform it into a space that truly
                      embodies your style and preferences.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block4.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <h4>
                      "Contact us today to receive expert advice from our top interior designers, and together, we will create a living room that not only
                      reflects your personality but also fits your budget. "
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>
        <Footer />
      </>
    );
  }
}

export default LivingRoom;
