import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
  {
    count: 1,
    title: 'Residential Interior Design',
    flaticon: 'flaticon-city',
    description:
      'We specialize in creating personalized interior design plans for homes, apartments, and villas, ensuring a harmonious blend of style, functionality, and comfort.',
    description1:
      'Our services encompass space planning, furniture selection, layout optimization, color schemes, lighting design, and the incorporation of decorative elements.',
    description2:
      'We pay meticulous attention to detail in kitchen and bathroom design, including fixtures, finishes, window treatments, flooring, and wall coverings.',
  },
  {
    count: 2,
    title: 'Commercial Interior Design',
    flaticon: 'flaticon-paint',
    description:
      'From office spaces to hospitality establishments, retail stores, and healthcare facilities, we offer comprehensive commercial interior design solutions.',
    description1:
      'Our expertise includes office space planning, layout design, reception areas, lobby design, conference room and boardroom design, retail store and showroom design, and hospitality and restaurant interiors.',
    description2:
      'We bring functionality, aesthetics, and brand identity together to create environments that enhance productivity, engagement, and customer experiences.',
  },
  {
    count: 3,
    title: 'Project Management',
    flaticon: 'flaticon-crane',
    description: 'We offer end-to-end project management services to ensure seamless execution and timely completion of projects.',
    description1:
      'Our dedicated team handles budgeting, cost estimation, material selection, procurement, contractor and vendor coordination, construction supervision, quality control, and project delivery.',
    description2: 'We take pride in our ability to effectively manage all aspects of a project, ensuring a smooth and stress-free experience for our clients.',
  },
  {
    count: 4,
    title: 'Renovation and Remodeling',
    flaticon: 'flaticon-decorating',
    description: 'Whether its breathing new life into an existing space or reimagining its layout, we specialize in renovation and remodeling projects.',
    description1:
      'Our services encompass a thorough assessment and evaluation of the space, conceptualization and design of renovation plans, structural modifications, space optimization, upgrades to lighting, flooring, and fixtures, as well as the selection of new furnishings and accessories.',
    description2: '',
  },
  {
    count: 5,
    title: 'Custom Furniture and Cabinetry',
    flaticon: 'flaticon-chart',
    description: 'We offer bespoke furniture design and fabrication services, creating unique pieces that perfectly complement your interior design vision.',
    description1: 'Our custom cabinetry solutions cater to kitchens, bathrooms, and storage needs, ensuring functionality, durability, and style.',
    description2:
      'We meticulously select high-quality materials and finishes, and integrate a perfect balance of functionality and aesthetics into each custom furniture and cabinetry project.',
  },
];

var img1 = require('./../../images/background/bg-5.png');
var img2 = require('./../../images/background/line.png');

class OurServices3 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-dark mobile-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-center">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">All</span>
                    <span className="font-weight-300 text-white"> Services</span>
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                {services.map((item, index) => (
                  <div key={index} className="col-md-6 col-sm-8">
                    <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white">
                      <div
                        className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-primary  scale-in-center bg-moving"
                        style={{ backgroundImage: 'url(' + img2 + ')' }}
                      >
                        <span className="icon-cell text-secondry">
                          <i className={item.flaticon} />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">{item.title}</h4>
                        <p>{item.description}</p>
                        <p>{item.description1}</p>
                        <p>{item.description2}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurServices3;
