import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/studytable/study-table-1.jpeg'),
    image: require('./../../images/jsinterio/services/studytable/study-table-1.jpeg'),
    title: 'Study Table',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/studytable/study-table-2.jpeg'),
    image: require('./../../images/jsinterio/services/studytable/study-table-2.jpeg'),
    title: 'Study Table',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/studytable/study-table-3.jpeg'),
    image: require('./../../images/jsinterio/services/studytable/study-table-3.jpeg'),
    title: 'Study Table',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/studytable/study-table-4.jpeg'),
    image: require('./../../images/jsinterio/services/studytable/study-table-4.jpeg'),
    title: 'Study Table',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/studytable/study-table-5.jpeg'),
    image: require('./../../images/jsinterio/services/studytable/study-table-5.jpeg'),
    title: 'Study Table',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/studytable/study-table-6.jpeg'),
    image: require('./../../images/jsinterio/services/studytable/study-table-6.jpeg'),
    title: 'Study Table',
    filter: 'cat-3',
  },
];

class StudyTable extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Study Table" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Study Table</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        A study table is a specialized piece of furniture designed specifically to facilitate studying, reading, or working. It typically
                        comprises a flat surface supported by legs or a sturdy base, creating a stable and comfortable workspace. Study tables are available in
                        a wide range of sizes, styles, and materials to cater to diverse needs and personal preferences.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/studytable/study-table-7.jpeg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/studytable/study-table-5.jpeg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/studytable/study-table-6.jpeg')} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="mt-post-text">
                    <p>
                      These tables often incorporate features that enhance productivity and organization. They may include drawers, shelves, or compartments for
                      storing books, stationery, and other study materials. Additionally, some study tables may be equipped with built-in lighting fixtures or
                      provisions for placing desk lamps, ensuring optimal illumination for studying or reading.
                    </p>
                    <h4>"These tables often incorporate features that enhance productivity and organization."</h4>
                    <p>
                      {' '}
                      The design and functionality of study tables can vary significantly. While some tables boast a simple and minimalistic design, others
                      offer advanced features such as adjustable heights, ergonomic designs, or built-in power outlets to conveniently charge electronic
                      devices. Choosing the right study table depends on individual preferences, available space, and the specific requirements of the user.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        We understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your satisfaction.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      Having a dedicated study table provides a designated area that promotes focused work, minimizes distractions, and facilitates better
                      organization and productivity.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <p>
                      These tables are commonly found in homes, schools, libraries, and various educational or work environments. They play a crucial role in
                      creating an environment conducive to effective studying, reading, or working.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default StudyTable;
