import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/services/crockery/crockery-1.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/crockery/crockery-1.jpg'),
    image: require('./../../images/jsinterio/services/crockery/crockery-1.jpg'),
    title: 'Crockery',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/crockery/crockery-2.jpg'),
    image: require('./../../images/jsinterio/services/crockery/crockery-2.jpg'),
    title: 'Crockery',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/crockery/crockery-3.jpg'),
    image: require('./../../images/jsinterio/services/crockery/crockery-3.jpg'),
    title: 'Crockery',
    filter: 'cat-3',
  },
];

class CrockeryUnit extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="Crockery Unit" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">Crockery Unit</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        A crockery unit, which is also referred to as a crockery cabinet or crockery cupboard, is a specialized furniture piece meticulously
                        designed to store and exhibit an assortment of crockery items including dishes, plates, bowls, cups, glasses, and various tableware.
                        These units are commonly found in dining rooms, kitchens, or even living rooms, seamlessly blending with different interior decor
                        styles.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/crockery/crockery-1.jpg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/crockery/crockery-2.jpg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/crockery/crockery-3.jpg')} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="mt-post-text">
                    <p>
                      Available in an extensive array of styles, sizes, and designs, crockery units cater to diverse preferences and individual requirements.
                    </p>
                    <h4>
                      "A crockery unit, typically incorporate well-structured shelves, drawers, and cabinets that offer ample storage space, ensuring efficient
                      organization and protection of delicate crockery. "
                    </h4>
                    <p>
                      Additionally, certain units may feature elegant glass doors or transparent panels, allowing for a captivating display of the crockery
                      collection while simultaneously safeguarding it from dust.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        We understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your satisfaction.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      Functioning as both utilitarian and decorative elements, crockery units play a crucial role in maintaining the orderliness, accessibility,
                      and preservation of crockery items, guarding them against potential breakage and dust accumulation.
                    </p>

                    <p>
                      Moreover, these units contribute to the overall ambiance of a room by introducing a touch of sophistication and serving as an attractive
                      platform to exhibit exquisite and valuable crockery pieces.
                    </p>

                    <h4>
                      When selecting a suitable crockery unit, it is essential to consider factors such as size, material, design, and storage capacity,
                      tailoring the choice to individual needs and available space.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default CrockeryUnit;
