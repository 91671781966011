import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

var bnrimg = require('./../../images/jsinterio/detailpage/master-bedroom-banner.jpg');

const block1 = [
  {
    thumb: require('./../../images/jsinterio/services/tvunit/tv-unit-1.jpeg'),
    image: require('./../../images/jsinterio/services/tvunit/tv-unit-1.jpeg'),
    title: 'TV Unit',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/tvunit/tv-unit-2.jpeg'),
    image: require('./../../images/jsinterio/services/tvunit/tv-unit-2.jpeg'),
    title: 'TV Unit',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/tvunit/tv-unit-3.jpeg'),
    image: require('./../../images/jsinterio/services/tvunit/tv-unit-3.jpeg'),
    title: 'TV Unit',
    filter: 'cat-3',
  },
];
const block2 = [
  {
    thumb: require('./../../images/jsinterio/services/tvunit/tv-unit-4.jpeg'),
    image: require('./../../images/jsinterio/services/tvunit/tv-unit-4.jpeg'),
    title: 'TV Unit',
    filter: 'cat-1',
  },
  {
    thumb: require('./../../images/jsinterio/services/tvunit/tv-unit-5.jpeg'),
    image: require('./../../images/jsinterio/services/tvunit/tv-unit-5.jpeg'),
    title: 'TV Unit',
    filter: 'cat-2',
  },
  {
    thumb: require('./../../images/jsinterio/services/tvunit/tv-unit-1.jpeg'),
    image: require('./../../images/jsinterio/services/tvunit/tv-unit-1.jpeg'),
    title: 'TV Unit',
    filter: 'cat-3',
  },
];

class TVUnit extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/masonary.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      items: 1,
      dots: false,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
    };
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner title="Making your vision come true, that is what we do." pagename="TV Unit" bgimage={bnrimg} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 square_shape1 square_shape3 inner-page-padding">
            <div className="container">
              {/* BLOG START */}
              <div className="blog-single-wrap-width" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media">
                    {/*Fade slider*/}
                    <div className="section-head">
                      <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                          <h2 className="text-white text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">TV Unit</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-post-text text-center">
                      <h5>
                        When choosing a TV unit, it is essential to carefully consider factors such as the dimensions and weight of your TV, the available space
                        within your room, and the specific storage requirements for your media devices. By taking these aspects into account, you can ensure
                        that the TV unit you select not only complements your home decor but also effectively accommodates and showcases your television while
                        providing convenient storage solutions for your media equipment.
                      </h5>
                    </div>
                    <OwlCarousel className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30" {...options}>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/tvunit/tv-unit-3.jpeg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/tvunit/tv-unit-4.jpeg')} alt="" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="aon-thum-bx">
                          <img src={require('./../../images/jsinterio/services/tvunit/tv-unit-5.jpeg')} alt="" />
                        </div>
                      </div>
                    </OwlCarousel>
                    {/*fade slider END*/}
                  </div>
                  <div className="mt-post-text">
                    <p>
                      TV units are available in a wide variety of styles, sizes, and materials, allowing you to find one that seamlessly integrates with your
                      existing interior design. Whether you prefer a sleek and modern look, a rustic and vintage aesthetic, or something in between, there is a
                      TV unit that can enhance the overall ambiance of your space.
                    </p>
                    <h4>"There is a TV unit that can enhance the overall ambiance of your space."</h4>
                    <p>
                      {' '}
                      In addition to their aesthetic appeal, many TV units offer practical features to enhance your viewing experience. Some models come
                      equipped with built-in cable management systems, which help to keep your cords organized and hidden from view, resulting in a clean and
                      clutter-free entertainment area. Swivel mounts are another popular feature that allows you to easily adjust the angle or height of your
                      TV, providing optimal viewing angles for everyone in the room.
                    </p>
                    <blockquote className="bg-dark text-white author-quote">
                      <h4 className="m-b0">
                        We understand that budget is a consideration, and our aim is to provide affordable prices while prioritizing your satisfaction.
                        <i className="fa fa-quote-right" />{' '}
                      </h4>
                      {/* <div className="p-t15  text-white">
                        <strong className="text-primary">Jessica Mcdade</strong>
                        <span>Contractor</span>
                      </div> */}
                    </blockquote>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block1.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <p>
                      Moreover, TV units often incorporate additional storage space to accommodate your collection of DVDs, Blu-rays, gaming accessories, remote
                      controls, and other media-related items. This storage capacity allows you to keep everything neatly organized and within reach,
                      eliminating the need for separate storage solutions and ensuring that your entertainment area remains tidy and functional.
                    </p>
                    <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                      {block2.map((item, index) => (
                        <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}>
                          <div className="project-classic">
                            <div className="mt-box ">
                              <div className="mt-thum-bx  img-center-icon  mt-img-overlay2">
                                <img src={item.thumb} alt="" />
                                <div className="overlay-bx">
                                  <div className="overlay-icon">
                                    <a className="mfp-link" href={item.image}>
                                      <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <h4>
                      The selection of colors, textures, and decorative elements contributes to the overall atmosphere, evoking a sense of tranquility and
                      comfort.{' '}
                    </h4>
                    <p>
                      By carefully considering the size and weight of your TV, the available space in your room, and your specific storage needs, you can make
                      an informed decision when selecting a TV unit that not only meets your functional requirements but also enhances the overall aesthetic and
                      functionality of your living space.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default TVUnit;
