import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
  {
    title: 'Living Room',
    icon: require('./../../images/jsinterio/icon/tvunit-icon.png'),
    description: 'Elevate your modern living room design with confidence as Interior Company offers an extensive selection...',
    link: '/living-room',
  },
  {
    title: 'Kitchen',
    icon: require('./../../images/jsinterio/icon/kitchen-icon.png'),
    description: 'Immerse yourself in a world of stylish and innovative modular kitchens that will revolutionize the focal point of your home...',
    link: '/kitchen',
  },
  {
    title: 'Wardrobes',
    icon: require('./../../images/jsinterio/icon/wardrobes.png'),
    description: `Elevate your bedroom to new heights with our meticulously crafted wardrobe designs that effortlessly harmonize with your lifestyle...`,
    link: '/wardrobes',
  },
  {
    title: 'TV Units',
    icon: require('./../../images/jsinterio/icon/tv.png'),
    description: `Discover our extensive collection of exquisitely crafted TV units that not only serve as a stunning focal point in your room but also elevate...`,
    link: '/tvunit',
  },
  {
    title: 'Guest Room',
    icon: require('./../../images/jsinterio/icon/guestroom-icon.png'),
    description: `Guest rooms are typically furnished with a comfortable bed or beds, along with basic amenities to ensure the guests...`,
    link: '/guest-bed-room',
  },
  {
    title: 'Dining Room',
    icon: require('./../../images/jsinterio/icon/dining-icon.png'),
    description:
      'In most homes, the dinning area is furnished with a well-proportioned dining table and a set of chairs, creating a comfortable and functional ...',
    link: '/dining-room',
  },
  {
    title: 'Kids Room',
    icon: require('./../../images/jsinterio/icon/kidsbedroom-icon.png'),
    description: `Embark on a captivating journey of creativity and imagination with Interior Company's meticulously crafted kids room designs..`,
    link: '/kids-bed-room',
  },
  {
    title: 'Master Bedroom',
    icon: require('./../../images/jsinterio/icon/mbedroom-icon.png'),
    description: 'Experience the pinnacle of comfort and tranquility with a master bedroom design brought to you by Interior Company...',
    link: '/master-bed-room',
  },
  {
    title: 'Pooja Room',
    icon: require('./../../images/icon/architecture.png'),
    description: 'Embrace the divine aura within your home with a pooja mandir, a profoundly sacred and auspicious piece of furniture...',
    link: '/pooja-units',
  },
  {
    title: 'Foyer',
    icon: require('./../../images/jsinterio/icon/foyer-icon.png'),
    description: `Elevate the grandeur of your home's entrance with a captivating foyer, also known as an entry hall or entryway. Foyers serve as a remarkable space to make a striking design statement. To complement and enhance this distinguished area, foyer tables come into play.`,
    link: '/foyer-units',
  },
  {
    title: 'Study Units',
    icon: require('./../../images/jsinterio/icon/study.png'),
    description: `Unleash your full potential and cultivate an ideal work environment with a meticulously tailored study table...`,
    link: '/study-table',
  },
  {
    title: 'Crockery Units',
    icon: require('./../../images/jsinterio/icon/crockery.png'),
    description: `A crockery unit is a meticulously crafted furniture piece designed specifically to store and showcase an array of crockery, including dinnerware, glassware, and other tableware items. Its thoughtful design incorporates well-organized shelves, drawers, and compartments that ensure the orderly and visually pleasing display of crockery. Available in a diverse range of styles, sizes, and materials`,
    link: '/crockery-units',
  },
  {
    title: 'Shoe Racks',
    icon: require('./../../images/jsinterio/icon/shoe-rack.png'),
    description: `Maintain an organized and pristine entryway while safeguarding your cherished shoe collection with the aid of a well-designed shoe rack. Whether you seek to maximize space efficiency or showcase your shoes in style, there exists a shoe rack tailored to your preferences. The design possibilities for shoe racks are diverse, ranging from classic wooden shelves with doors`,
    link: '/shoe-rack',
  },
];

var img1 = require('./../../images/slider/living.jpeg');

class OurServices extends React.Component {
  // componentDidMount(){
  //     function loadScript(src) {

  //       return new Promise(function(resolve, reject){
  //         var script = document.createElement('script');
  //         script.src = src;
  //         script.addEventListener('load', function () {
  //           resolve();
  //         });
  //         script.addEventListener('error', function (e) {
  //           reject(e);
  //         });
  //         document.body.appendChild(script);
  //         document.body.removeChild(script);
  //       })
  //     };

  //   loadScript('./assets/js/masonary.js');

  // };
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding  p-b50  square_shape2">
          <div className="section-content">
            <div className="Service-half-top p-t80  bg-dark bg-moving" style={{ backgroundImage: 'url(' + img1 + ')' }}>
              <div className="container">
                {/* TITLE START */}
                <div className="section-head text-white">
                  <div className="mt-separator-outer separator-left">
                    <div className="mt-separator">
                      <h2 className="text-white text-uppercase sep-line-one ">
                        <span className="font-weight-300 text-primary">Our</span> Services
                      </h2>
                    </div>
                  </div>
                  <h3>
                    Our services includes both an artistic and scientific discipline that aims to improve the interior of a building, creating a space that not
                    only enhances the well-being and comfort of its occupants but also offers a visually pleasing and harmonious environment.
                  </h3>
                </div>
                {/* TITLE END */}
              </div>
            </div>
            <div className="services-half-bottom">
              <div className="container">
                <div className="row">
                  {services.map((item, index) => (
                    <div className="col-md-3 col-sm-6" key={index}>
                      <div className="mt-icon-box-wraper m-b30">
                        <div className="relative icon-count-2 bg-gray p-a30 p-tb50">
                          <span className="icon-count-number">{index + 1}</span>
                          <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                            <span className="icon-cell">
                              <img src={item.icon} alt="" />
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="mt-tilte m-b25">{item.title}</h4>
                            <p className="max-line-4">{item.description}</p>
                            {item.link && (
                              <NavLink to={item.link} className="site-button-link" data-hover="Read More">
                                Read More <i className="fa fa-angle-right arrow-animation" />
                              </NavLink>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default OurServices;
