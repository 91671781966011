import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-6.png');

class Specialization3 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-white mobile-page-padding" style={{ backgroundImage: 'url(' + bnr1 + ')' }}>
          <div className="container">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="section-head">
                <div className="mt-separator-outer separator-center">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">Why</span>
                      <span className="font-weight-300 text-dark"> Choose Us</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div key={1} className="col-md-4 col-sm-6">
                  <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white">
                    <div className="icon-content">
                      <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">Experience</h4>
                      <p>With over 12 years of industry experience, we have a proven track record of delivering successful projects on time.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div key={2} className="col-md-4 col-sm-6">
                  <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white">
                    <div className="icon-content">
                      <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">Professional Team</h4>
                      <p>
                        Our team comprises talented interior designers, architects, project managers, and skilled craftsmen who collaborate to bring your vision
                        to life.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div key={3} className="col-md-4 col-sm-6">
                  <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white">
                    <div className="icon-content">
                      <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">Client-Centric Approach</h4>
                      <p>
                        We prioritize client satisfaction and actively involve our clients throughout the design process, ensuring their needs and preferences
                        are met at every stage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-b50 text-center">
                <div key={4} className="col-md-6 col-sm-8">
                  <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white">
                    <div className="icon-content">
                      <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">Attention to Detail</h4>
                      <p>
                        We have a keen eye for detail and take pride in our ability to curate spaces that exude style, functionality, and attention to every
                        aspect, from materials to final touches.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-b50 text-center">
                <div key={5} className="col-md-6 col-sm-8">
                  <div className="mt-icon-box-wraper p-a30 center m-b30 box-shadow bg-white">
                    <div className="icon-content">
                      <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">Quality and Reliability</h4>
                      <p>
                        We are committed to delivering high-quality workmanship and materials, and we strive to complete projects on time and within budget.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-4 col-sm-6 m-b30">
              <div className="image-effect-one hover-shadow">
                <img src={require('../../images/services/1.jpg')} alt="" />
                <div className="figcaption">
                  <h4>Concept and Design</h4>
                  <p>Est notare quam littera gothica, quam nunc putamus parum claram.</p>
                  <NavLink to="/project-detail">
                    <i className="link-plus bg-primary" />
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 m-b30">
              <div className="image-effect-one hover-shadow">
                <img src={require('../../images/services/2.jpg')} alt="" />
                <div className="figcaption">
                  <h4>Refurbishment</h4>
                  <p>Est notare quam littera gothica, quam nunc putamus parum claram.</p>
                  <NavLink to="/project-detail">
                    <i className="link-plus bg-primary" />
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 m-b30">
              <div className="image-effect-one hover-shadow">
                <img src={require('../../images/services/3.jpg')} alt="" />
                <div className="figcaption bg-dark">
                  <h4>General Builder</h4>
                  <p>Est notare quam littera gothica, quam nunc putamus parum claram.</p>
                  <NavLink to="/project-detail">
                    <i className="link-plus bg-primary" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

export default Specialization3;
