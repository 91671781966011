import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import About4 from './../Elements/About4';
import OurServices3 from './../Elements/OurServices3';
import About from './../Elements/About';
import Specialization3 from './../Elements/Specialization3';

var bnrimg = require('./../../images/banner/1.jpg');

class AboutUs extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner title="Committed to superior interior works." pagename="About Us" bgimage={bnrimg} />
          <About />
          <About4 />
          <OurServices3 />
          <Specialization3 />
        </div>
        <Footer />
      </>
    );
  }
}

export default AboutUs;
